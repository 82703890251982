.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

/* nav */
.navbar {
  background-color: #000;
  color: #fff;
  padding: 10px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  height: 40px; /* Adjust height as needed */
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  display: inline;
  margin-right: 20px;
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
}

.nav-links a:hover {
  color: #ccc;
}

/* footer */
.footer {
  background-color: #000;
  color: #fff;
  padding: 20px 0;
  /* //position: fixed; */
  bottom: 0;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo img {
  height: 30px; /* Adjust height as needed */
}

.footer-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-links li {
  display: inline;
  margin-right: 20px;
}

.footer-links li:last-child {
  margin-right: 0;
}

.footer-links a {
  text-decoration: none;
  color: #fff;
}

.footer-links a:hover {
  color: #ccc;
}

.footer-text p {
  margin: 0;
}

/* Main */

.container {
  margin: 12px auto;
  width: 80%;
}

.navigation-widgets {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.navigation-widget {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.navigation-widget h2 {
  margin-top: 0;
}

.navigation-widget p {
  margin-bottom: 10px;
}

.navigation-widget a {
  display: block;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  padding: 10px 0;
  border-radius: 5px;
}

.navigation-widget a:hover {
  background-color: #0056b3;
}
/*  */

.container {
  margin: 12px auto;
  width: 80%;
}

.navigation-widgets {
  display: flex;
  justify-content: center; /* Align tabs in the center */
  gap: 20px; /* Add spacing between tabs */
  margin-top: 50px;
}

.navigation-widget {
  /* flex: 1; */
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f9fa; /* Light background color */
  transition: background-color 0.3s; /* Smooth transition on hover */
}

.navigation-widget:hover {
  background-color: #e9ecef; /* Darken background color on hover */
}

.navigation-widget h2 {
  margin-top: 0;
}

.navigation-widget p {
  margin-bottom: 10px;
}

.navigation-widget a {
  display: inline-block; /* Align link horizontally */
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px; /* Increase padding for better clickability */
  border-radius: 5px;
  transition: background-color 0.3s; /* Smooth transition on hover */
}

.navigation-widget a:hover {
  background-color: #0056b3;
}

/* outline */

.container {
  margin: 12px auto;
  width: 80%;
}

h1 {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow effect */
}

thead th {
  background-color: #f5f5f5; /* Light gray background for table header */
  border-bottom: 2px solid #ddd; /* Add bottom border to table header */
  padding: 10px;
}

tbody tr {
  border-bottom: 1px solid #ddd; /* Add bottom border to table rows */
}

tbody td {
  padding: 10px;
  text-align: center;
}

input[type="number"] {
  width: 50px;
  padding: 5px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* */
.container {
  margin: 12px auto;
  width: 80%;
}

h1, h2 {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

thead th {
  background-color: #f5f5f5;
  border-bottom: 2px solid #ddd;
  padding: 10px;
}

tbody tr {
  border-bottom: 1px solid #ddd;
}

tbody td {
  padding: 10px;
  text-align: center;
}

input[type="number"] {
  width: 50px;
  padding: 5px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.cart {
  margin-top: 50px;
}

.cart ul {
  list-style: none;
  padding: 0;
}

.cart li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.cart li:last-child {
  border-bottom: none;
}

.cart button {
  background-color: #dc3545; /* Red color for delete button */
}

.cart button:hover {
  background-color: #c82333; /* Darker red on hover */
  color: #fff;
}

/* sfda */



h1 {
  text-align: center;
}

.order-details {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.steps-container {
  text-align: center;
}

.steps {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  width: 80px;
  padding: 10px;
  background-color: #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.step.active {
  background-color: #007bff;
  color: #fff;
}

.step:hover {
  background-color: #0056b3;
}

/* asdf */

h1 {
  text-align: center;
}

.progress-container {
  text-align: center;
}

.progress {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.step {
  flex: 1;
  height: 2px;
  background-color: #007bff;
}

.step.completed {
  background-color: #007bff;
}

.labels {
  display: flex;
  justify-content: space-between;
}

.label {
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.label.active {
  color: #007bff;
}

/* admin */

/* AdminPage.css */

.admin-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.admin-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.admin-form {
  display: flex;
  flex-direction: column;
}

.admin-label {
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

.admin-select,
.admin-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.admin-button {
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.admin-button:hover {
  background-color: #0056b3;
}

.admin-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #dff0d8;
  color: #155724;
}
/**/
.shopping-cart-container {
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item-name {
  font-weight: bold;
}

.cart-item-quantity {
  color: #666;
}

.cart-item-remove {
  cursor: pointer;
  color: #f00;
}


.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.step {
  width: 100px;
  height: 30px;
  background-color: #ccc;
  border-radius: 5px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.active {
  background-color: #007bff;
  color: white;
}

h2 {
  margin-bottom: 10px;
}

.order-status, .payment-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.label {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 10px;
  background-color: #f0f0f0;
}

.label.active {
  background-color: #007bff;
  color: white;
}

.product-list-page {
  margin: 20px auto;
  width: 80%;
}

.location-input {
  margin-bottom: 20px;
}

.products-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.products-table th,
.products-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.products-table th {
  background-color: #f2f2f2;
}

.shopping-cart-container {
  border: 1px solid #ddd;
  padding: 20px;
}

.cart-item {
  list-style-type: none;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.submit-button,
.back-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
}

.submit-button:hover,
.back-button:hover {
  background-color: #0056b3;
}
